import Vue from 'vue';

const getDefaultMetadata = () => {
		return {
				// Project name
				title: "",
				// reviewer names
				reviewerNames: ["", ""]
		}
}

const state = getDefaultMetadata();

const getters = {
	// Get the entire refs object
	getTitle: state => {
		return state.title;
	},
	getReviewerNames: state => {
			return state.reviewerNames;
	}
}

const mutations = {
	reset(state) {
		Object.assign(state, getDefaultMetadata());
	},
	// Set metadata
	setMetadata(state, data) {
		// Set metadata
		Object.assign(state, data);
	},
	// Set title
	setTitle(state, title) {
		// Set title
		state.title = title;
	},
	// Set reviewer names
	setReviewerNames(state, reviewerNames) {
		state.reviewerNames = reviewerNames;
	},
	// Set a single reviewer name
	updateReviewerName(state, data) {
		// Set name
		Vue.set(state.reviewerNames, data.index, data.name);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}