<template>
	<div id="app">
		<b-navbar style="height: 40px;" type="dark" variant="dark" sticky>
			<b-navbar-brand href="#" variant="light">
				Disputatron
				<!-- <span v-if="refs">
					<span v-if="!isEditTitle">- {{ title ? title : "Untitled" }}</span>
					<input v-if="isEditTitle" ref="title-edit" :value="title" placeholder="Enter project name">
				</span> -->
			</b-navbar-brand>
			<!-- <b-navbar-nav v-if="refs">
				<b-button v-if="!isEditTitle" size="sm" class="mr-2" @click="isEditTitle = true"
				variant="primary">Edit</b-button>
				<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="saveTitle" variant="success">Save</b-button>
				<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="isEditTitle = false"
				variant="danger">Cancel</b-button>
			</b-navbar-nav> -->
			<b-navbar-nav class="ml-auto">
				<b-button size="sm" class="mr-2" @click="openHelp" variant="info">
					Help
				</b-button>
				<!-- <b-button v-if="refs" size="sm" v-b-modal.close-warning>
					Choose another library...
				</b-button> -->
			</b-navbar-nav>
		</b-navbar>

		<!-- Toolbar -->
		<div class="sticky-toolbar">
			<TheToolbar :isOpen="isOpen" @close="close()" />
		</div>

		<b-container class="mt-5">
			<b-row>
				<b-col>
					<div class="logo">
						<b-icon
						style="width: 150px; height: 150px;"
						icon="arrows-angle-contract"
						></b-icon>
						<h2>DISPUTATRON</h2>
					</div>
					<div class="stats-input">
						<b-form-input
						v-model="totalArticles"
						style="text-align: center"
						placeholder="Total Number of Articles Screened (Optional)"
						></b-form-input>
					</div>
					<b-button class="mb-3" v-on:click="addReviewer" variant="success"><b-icon-person-plus></b-icon-person-plus> Add Reviewer</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-for="(file, index) of files" :key="index">
					<p>Reviewer {{index + 1}} Include:</p>
					<div class="name-input">
						<b-input-group>
							<b-form-input
							:value="reviewerNames[index]"
							@change="(e) => $store.commit('metadata/updateReviewerName', { index: index, name: e })"
							:placeholder="`Reviewer ${index + 1} Name (Optional)`"
							></b-form-input>
							<template #append v-if="index > 1">
								<b-button @click="files.splice(index, 1)" variant="danger"><b-icon-x></b-icon-x></b-button>
							</template>
						</b-input-group>
					</div>
					<tera-file-select
					:title="`Select the citation library for ${reviewerNames[index] || 'Reviewer #' + (index+1)}`"
					:filters="{library: true}"
					:hint="`screened-${index+1}`"
					placeholder="Choose a library file..."
					@change="files[index] = $event"
					/>
				</b-col>
			</b-row>
			<b-row>
				<div id="combine-arrow"></div>
			</b-row>
			<b-row>
				<b-col>
					<b-icon id="down-arrow" icon="arrow-down"></b-icon>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-button v-on:click="submit">Submit</b-button>
				</b-col>
			</b-row>

			<div class="m-3" v-if="refs && totalArticles && !isLoading">
				<h3>Accuracy Measures</h3>
				<b-table
				class="light-table"
				bordered
				:fields="accFields"
				:items="accMeasure"
				head-variant="light"
				></b-table>
			</div>

			<b-row>
				<b-col class="p-3">
					<div v-if="refs && !isLoading">
						<h3>Screening refs</h3>
						<b-form-group>
							<b-form-radio v-model="showAgreements" :value="false">
								Display just screening conflicts ({{ this.refs.filter(el => el.isConflict).length }})
							</b-form-radio>
							<b-form-radio v-model="showAgreements" :value="true">
								Display both screening conflicts and agreements ({{
									this.refs.length
								}})
							</b-form-radio>
						</b-form-group>
						<b-button variant="primary" v-b-modal.export>
							Export
						</b-button>
						<div>
							<b-table
							class="light-table m-2"
							style="text-align: left;"
							bordered
							:items="displayArticles"
							:fields="fields"
							:tbody-tr-class="rowClass"
							head-variant="light"
							>
							<template v-slot:cell(include)="data">
								<b-form-checkbox
								v-model="data.item.include"
								@input="e => $store.commit('references/updateRef', data.item)"
								switch
								>
								{{ data.item.include ? "Include" : "Exclude" }}
							</b-form-checkbox>
						</template>

						<template v-slot:cell(authors)="data">
							{{ formatArray(data.value) }}
						</template>

						<template v-slot:cell(abstract)="data">
							<b-button size="sm" @click="data.toggleDetails" class="mr-2">
								{{ data.detailsShowing ? "Hide" : "Show" }} Abstract
							</b-button>
						</template>

						<template v-slot:row-details="data">
							<b-card bg-variant="secondary" text-variant="white">
								{{ data.item.abstract }}
							</b-card>
						</template>
					</b-table>
				</div>

				<b-button variant="primary" v-b-modal.export>
					Export
				</b-button>
			</div>
			<b-spinner v-else-if="isLoading"></b-spinner>
		</b-col>
	</b-row>
</b-container>
<b-modal title="Export" id="export" :hide-footer="true">
	<ExportModal />
</b-modal>
</div>
</template>

<script>
import TeraFileSelect from '@iebh/tera-fy/widgets/tera-file-select.vue';
import ExportModal from "./components/ExportModal.vue";
import TheToolbar from "./components/TheToolbar.vue";

import { mapState } from "vuex";

export default {
	components: {
		ExportModal,
		TeraFileSelect,
		TheToolbar
	},
	data() {
		return {
			files: [null, null],
			isLoading: false,
			isEditTitle: false,
			// Variables to store the input/output URL for SRA integration
			in: [],
			out: null,
			// Variable to store whether to show refs only or refs and agreements
			showAgreements: false,
			fields: [
			{
				key: "include"
			},
			{
				key: "year",
				sortable: true
			},
			{
				key: "title",
				sortable: true
			},
			{
				key: "authors",
				sortable: true
			},
			{
				key: "abstract"
			},
			{
				key: "conflict",
				sortable: true
			}
			],
			accFields: [
			{
				key: "kappa",
				label: "Cohen's Kappa coefficient"
			},
			{
				key: "pabak",
				label: "PABAK coefficient"
			},
			{
				key: "agree",
				label: "Percentage of article agreements"
			},
			{
				key: "includeAgree",
				label: "Proportion of agreed included articles"
			},
			{
				key: "excludeAgree",
				label: "Proportion of agreed excluded articles"
			},
			{
				key: "conflicting",
				label: "Proportion of article disagreements"
			}
			]
		};
	},
	computed: {
		...mapState({
			options: state => state.options
		}),
		// Vuex Members
		title: {
			get() {
				return this.$store.state.metadata.title;
			},
			set(title) {
				this.$store.commit('metadata/setTitle', title);
			}
		},
		reviewerNames: {
			get() {
				return this.$store.state.metadata.reviewerNames;
			},
			set(reviewerNames) {
				if (reviewerNames) {
					this.$store.commit('metadata/setReviewerNames', reviewerNames);
				}
			}
		},
		refs: {
			get() {
				return this.$store.state.references.refs
			},
			set(refs) {
				this.$store.commit('references/setRefs', refs)
			}
		},
		totalArticles: {
			get() {
				return this.$store.state.references.totalArticles
			},
			set(totalArticles) {
				this.$store.commit('references/setTotalArticles', totalArticles)
			}
		},
		// Computed
		isOpen() {
			if (this.refs || this.totalArticles) {
				return true;
			} else {
				return false;
			}
		},
		displayArticles() {
			if (this.showAgreements) {
				return this.refs;
			} else {
				return this.refs.filter(el => el.isConflict);
			}
		},
		accMeasure() {
			const numberAgreed = this.refs.filter(el => !el.isConflict).length;
			const numberConflicts = this.refs.filter(el => el.isConflict).length;

			if (this.refs && this.totalArticles) {
				const kappa = this.calcKappa();
				const includeAgree = numberAgreed / this.totalArticles;
				const excludeAgree =
				(this.totalArticles -
				numberAgreed -
				numberConflicts) /
				this.totalArticles;
				const agree = (includeAgree + excludeAgree) * 100;
				const pabak = 2 * (includeAgree + excludeAgree) - 1;

				const agreeString = `${agree.toFixed(2)}%`;
				const includeAgreeString = `${numberAgreed} / ${this.totalArticles}`;
				const excludeAgreeString = `${this.totalArticles - numberAgreed - numberConflicts} /
				${this.totalArticles}`;
				const conflictString = `${numberConflicts} / ${this.totalArticles}`;
				return [
				{
					kappa: kappa.toFixed(3),
					pabak: pabak.toFixed(3),
					agree: agreeString,
					includeAgree: includeAgreeString,
					excludeAgree: excludeAgreeString,
					conflicting: conflictString
				}
				];
			} else {
				return [];
			}
		}
	},
	methods: {
		addReviewer() {
			this.files.push(new File([], ""));
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.$refs['title-edit'].value);
			this.isEditTitle = false;
		},
		calcKappa() {
			if (this.totalArticles && this.refs) {
				const numberAgreed = this.refs.filter(el => !el.isConflict).length;
				const numberConflicts = this.refs.filter(el => el.isConflict).length;

				const a = numberAgreed;
				const b = this.refs.filter(
				val =>
				val.conflict ===
				"Included by " +
				(this.reviewerNames[0] ? this.reviewerNames[0] : "Reviewer 1")
				).length;
				const c = this.refs.filter(
				val =>
				val.conflict ===
				"Included by " +
				(this.reviewerNames[1] ? this.reviewerNames[1] : "Reviewer 2")
				).length;
				const d =
				this.totalArticles -
				numberAgreed -
				numberConflicts;

				const p0 = (a + d) / this.totalArticles;
				const pc =
				((a + b) / this.totalArticles) * ((a + c) / this.totalArticles);
				const pi =
				((c + d) / this.totalArticles) * ((b + d) / this.totalArticles);
				const pe = pc + pi;

				return (p0 - pe) / (1 - pe);
			} else {
				console.log("Error Calculating Kappa");
				return 0;
			}
		},
		close() {
			this.$store.dispatch('resetStore');
		},
		openHelp() {
			window.open("http://sr-accelerator.com/#/help/disputatron", "_blank");
		},
		rowClass(item, type) {
			if (!item || type !== "row") return;
			if (item.include) return "table-info";
		},
		formatArray(arr) {
			let string = "";
			if (arr.length > 0) {
				for (let i = 0; i < arr.length - 1; i++) {
					string = string.concat(arr[i] + ", ");
				}
				string = string.concat(arr[arr.length - 1]);
			}
			return string;
		},
		readFile(file) {
			return new Promise(resolve => {
				const reader = new FileReader();
				reader.readAsText(file);
				reader.onloadend = () => resolve(reader.result);
			});
		},
		getFileExtention(name) {
			return "." + name.split(".").pop();
		},
		hashCompute(ref) {
			return [ref.year, ref.title, ref.volume].filter(i => i).join("-");
		},
		compareHash(a, b) {
			if (a.hash && b.hash) {
				if (a.hash < b.hash) {
					return -1;
				}
				if (a.hash > b.hash) {
					return 1;
				}
				return 0;
			} else {
				throw Error("No hash calculated for ref");
			}
		},
		findConflicts(libraries) {
			const returnRefs = [];
			const allRefs = new Map();

			// First, collect all unique references
			libraries.forEach((library, libIndex) => {
				library.forEach(ref => {
					const hash = this.hashCompute(ref);
					if (!allRefs.has(hash)) {
						allRefs.set(hash, { ref: { ...ref }, includedBy: new Set() });
					}
					allRefs.get(hash).includedBy.add(libIndex);
				});
			});

			// Then, process conflicts
			for (const [, { ref, includedBy }] of allRefs) {
				const isConflict = includedBy.size !== libraries.length;
				returnRefs.push({
					...ref,
					isConflict,
					conflict: isConflict
					? `Included by ${Array.from(includedBy).map(i => this.reviewerNames[i] || `Reviewer ${i + 1}`).join(", ")}`
					: "Included by all",
					include: !isConflict, // Include if all agree
				});
			}

			// Sort by hash to maintain consistent order
			returnRefs.sort((a, b) => this.hashCompute(a).localeCompare(this.hashCompute(b)));

			return returnRefs;
		},
		submit() {
			this.isLoading = true;
			// Check that all files exist
			for (const file of this.files) {
				if (!file) {
					this.$tera.uiAlert(`You must provide ${this.files.length == 2 ? 'both' : 'All ' + this.files.lenth} files`);
					this.isLoading = false;
					return;
				}
			}
			return Promise.all(this.files.map(file =>
			file.getRefs()
			)).then(libraries => {
				console.log(libraries);
				this.refs = this.findConflicts(libraries);
				this.isLoading = false;
			});
		}
	}
};
</script>

<style>
#app {
	/* font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; */
	text-align: center;
	color: #2c3e50;
	background-color: rgb(250, 250, 250);
	min-height: 100vh;
}

.sticky-toolbar {
	position: fixed;
	z-index: 5;
	width: 100%;
	/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.light-table {
	background-color: rgb(255, 255, 255);
}

.logo {
	color: #4c0080;
}

#combine-arrow {
	margin-right: 10em;
	margin-left: 10em;
	border: 8px solid #2c3e50;
	border-style: hidden solid solid solid;
	border-radius: 0px 0px 20px 20px;
	width: 100%;
	height: 100px;
}

#down-arrow {
	width: 100px;
	height: 100px;
	color: #2c3e50;
	margin-top: -10px;
}

.name-input {
	padding: 10px 10px 10px 10px;
}

.stats-input {
	max-width: 400px;
	margin: 40px auto 10px auto;
}
</style>
