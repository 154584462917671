class DefaultOptions {
	fileName = "disputes_resolved";
	fileType = {
		driver: "endnotexml",
		extention: ".xml"
	};
	conflictField = "label";
}

const state = new DefaultOptions();

const mutations = {
	reset(state) {
		Object.assign(state, new DefaultOptions());
	},
	setFileName(state, fileName) {
		state.fileName = fileName;
	},
	setFileType(state, fileType) {
		state.fileType = fileType;
	},
	setConflictField(state, conflictField) {
		state.conflictField = conflictField;
	}
}

export default {
	namespaced: true,
	state,
	mutations
}