import Vue from "vue";
import Vuex from "vuex";

import metadata from "./modules/metadata";
import options from "./modules/options";
import references from "./modules/references";

import { createSyncPlugin } from '@iebh/vuex-tera-sync';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		metadata,
		options,
		references
	},
	plugins: [createSyncPlugin('disputatron')],
	actions: {
		resetStore({ commit }) {
			commit("metadata/reset");
			commit("options/reset");
			commit("references/reset");
		}
	}
});
