export const conflictFieldOptions = [
	{ value: "notes", text: "Notes Field" },
	{ value: "researchNotes", text: "Research Notes Field" },
	{ value: "label", text: "Label Field" },
	{ value: "caption", text: "Caption Field" },
	{ value: "urls", text: "URL Field" },
	{ value: null, text: "None/Dont Append" }
];

// export interface Ref {
//   recNumber?: string;
//   type?: string;
//   title?: string;
//   year?: string;
//   journal?: string;
//   authors?: string[];
//   date?: string;
//   urls?: string[];
//   pages?: string;
//   volume?: string;
//   number?: string;
//   isbn?: string;
//   abstract?: string;
//   label?: string;
//   caption?: string;
//   notes?: string;
//   address?: string;
//   researchNotes?: string;
//   keywords?: string[];
//   accessDate?: string;
//   accession?: string;
//   doi?: string;
//   section?: string;
//   language?: string;
//   databaseProvider?: string;
//   database?: string;
//   workType?: string;
//   custom1?: string;
//   custom2?: string;
//   custom3?: string;
//   custom4?: string;
//   custom5?: string;
//   custom6?: string;
//   custom7?: string;
//   hash?: string;
//   conflict?: string;
// }
