import filenameMixin from './filenameMixin';
import { mapState } from 'vuex/';

export default {
	mixins: [filenameMixin],
	computed: {
		...mapState({
			metadata: state => state.metadata.metadata,
			refs: state => state.references.refs,
			dupes: state => state.references.totalArticles
		}),
	},
	methods: {
		async save() {
			const metadata = this.metadata;
			const refs = this.refs;
			const totalArticles = this.totalArticles;

			const saveObject = {
				metadata,
				refs,
				totalArticles
			};

			const dataStr =
				"data:text/json;charset=utf-8," +
				encodeURIComponent(JSON.stringify(saveObject));
			const downloadAnchorNode = document.createElement("a");
			downloadAnchorNode.setAttribute("href", dataStr);
			downloadAnchorNode.setAttribute(
				"download",
				"disp_"
					+ this.getProjectName()
					+ " "
					+ this.getDateString()
					+ ".disp"
			);
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	}
}