<template>
	<div>
		<b-button-toolbar class="bg-secondary">
			<b-button-group>
				<b-dropdown right text="File" class="custom-shadow">
					<input
						type="file"
						@change="callLoadFile($event.target.files[0])"
						ref="loadFile"
						style="display: none"
						accept=".disp"
					/>
					<b-dropdown-item @click="$refs.loadFile.click()"
						>Load</b-dropdown-item
					>
					<span v-if="isOpen">
						<b-dropdown-item @click="save()">Save</b-dropdown-item>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item v-b-modal.export>Export</b-dropdown-item>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item v-b-modal.close-warning>Close</b-dropdown-item>
					</span>
				</b-dropdown>
				<b-button @click="openHelp">Help</b-button>
			</b-button-group>
		</b-button-toolbar>
		<b-modal id="close-warning" title="Warning">
			<p class="my-4">Warning: Make sure to save all work before closing</p>
			<template #modal-footer>
				<b-button @click="$bvModal.hide('close-warning')">
					Cancel
				</b-button>
				<b-button variant="info" @click="save">
					Save
				</b-button>
				<b-button variant="danger" @click="callClose">
					Close
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import saveMixin from "../mixins/saveMixin"
import loadMixin from "../mixins/loadMixin"
import exportMixin from "../mixins/exportMixin"

export default {
	name: "TheToolbar",
	props: {
		isOpen: Boolean
	},
	mixins: [saveMixin, loadMixin, exportMixin],
	methods: {
		callLoadFile(e) {
			this.load(e);
			this.$refs.loadFile.value = null;
		},
		callClose() {
			this.$emit("close");
			this.$bvModal.hide("close-warning");
		},
		openHelp() {
			window.open("https://sr-accelerator.com/#/help/disputatron", "_blank");
		}
	}
};
</script>

<style>
.dropdown.custom-shadow .dropdown-menu {
	box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5) !important;
}
</style>
