import { mapState } from "vuex";
import fileNameMixin from "./filenameMixin";

export default {
	mixins: [fileNameMixin],
	computed: {
		...mapState({
			refs: state => state.references.refs,
			options: state => state.options
		}),
	},
	methods: {
		downloadDiscarded() {
			return this.$tera.setProjectLibrary({
				title: 'Save discarded citations',
				hint: 'disputed-discarded',
				refs: this.refs.filter(
					article => !article.include
				),
			});
		},
		downloadIncluded() {
			if (this.refs) {
				// Filter through refs and only select those to be included
				const includedArticles = this.refs.filter(
					article => article.include
				);
				// Map the conflict message to a field for the XML file
				includedArticles.map(article => {
					if (this.options.conflictField) {
						article[this.options.conflictField] = article.conflict;
					}
				});

				return this.$tera.setProjectLibrary({
					title: 'Save included citations',
					hint: 'disputed-included',
					refs: includedArticles,
				});
			}
		},
	}
}
