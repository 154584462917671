import { mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			metadata: state => state.metadata
		}),
	},
	methods: {
		getProjectName() {
			return this.metadata.title ? this.metadata.title : "Untitled";
		},
		getDateString() {
			// Get date to append to file name
			const d = new Date(Date.now());
			const dateString = `${d.getFullYear()}-${('0'+(d.getMonth() + 1)).slice(-2)}-${('0'+d.getDate()).slice(-2)}_Time${('0'+d.getHours()).slice(-2)}${('0'+d.getMinutes()).slice(-2)}`;
			return dateString;
		}
	}
}