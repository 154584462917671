import Vue from "vue";

const state = {
	refs: null,
	totalArticles: null
};

const getters = {
	getRefs: state => {
		return state.refs;
	},
	getTotalArticles: state => {
		return state.totalArticles;
	}
};

const mutations = {
	reset(state) {
		state.refs = null;
		state.totalArticles = null;
	},
	setRefs(state, refs) {
		state.refs = refs;
	},
	concatRefs(state, refs) {
		state.refs = state.refs.concat(refs);
	},
	updateRef(state, data) {
		const i = state.refs.indexOf(data);
		// Update reference object at index i with new include value
		Vue.set(state.refs, i, { ...state.refs[i], include: data.include });
	},
	setTotalArticles(state, totalArticles) {
		state.totalArticles = totalArticles;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations
};
